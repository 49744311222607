import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GlobalContactForm } from '@phx-husky/global-contact-form';

import Layout from 'layout/Layout';
import HeroBanner from 'components/sections/HeroBanner';

import { IPropsContactUsPage } from './models';

import './ContactUsPage.scss';

const ContactUsPage: FC<IPropsContactUsPage> = ({ data: { pageData }, pageContext }) => {
  const {
    seo,
    urls,
    createDate,
    updateDate,
    heroBanner,
    contactForm,
    showBreadcrumbsDesktop,
    showBreadcrumbsMobile,
  } = pageData;
  const { breadcrumbs, lang, url } = pageContext;

  return (
    <Layout
      data-testid="ContactUs"
      seo={seo}
      lang={lang}
      urls={urls}
      pagePathname={url}
      createDate={createDate}
      updateDate={updateDate}
      className="contact-us"
    >
      <HeroBanner
        data={heroBanner}
        breadcrumbs={breadcrumbs}
        isShowBreadcrumbsMobile={showBreadcrumbsMobile}
        isShowBreadcrumbsDesktop={showBreadcrumbsDesktop}
      />
      {contactForm?.length ? (
        <div className="contact-us__wrapper">
          <GlobalContactForm
            src={contactForm[0].url}
            id="phx-global-form-gatsby"
            title={contactForm[0].title || ''}
            className="contact-form"
          />
        </div>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String!, $lang: String) {
    pageData: contactUsPage(url: { eq: $url }, lang: { eq: $lang }) {
      ...FragmentContactUsPage
    }
  }
`;

export default ContactUsPage;
